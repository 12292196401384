<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="row">
        <h3>Geral</h3>
        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title" title="nº clientes ativos">
              <span>Clientes Ativos</span>
            </li>
            <li class="list-group-item">
              <b>Nº Clientes:</b> {{ clientsCount.total }}
            </li>
            <li class="list-group-item">
              <b>Global:</b> {{ clientsCount.global }}
            </li>
            <li class="list-group-item">
              <b>Fiber:</b> {{ clientsCount.fiber }}
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item title"
              title="nº visita técnica pendentes"
            >
              <span>Qtd VT pendentes (total)</span>
            </li>
            <li class="list-group-item">
              <b>Nº VT:</b> {{ techVisitsCount.open.total }}
            </li>
            <li class="list-group-item">
              <b>Global:</b> {{ techVisitsCount.open.global }}
            </li>
            <li class="list-group-item">
              <b>Fiber:</b> {{ techVisitsCount.open.fiber }}
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item title"
              title="numero visita técnica do dia anterior"
            >
              <span>Qtd VT fechadas (ontem)</span>
            </li>
            <li class="list-group-item">
              <b>Nº VT:</b> {{ techVisitsCountFromDate.closed.total }}
            </li>
            <li class="list-group-item">
              <b>Global:</b> {{ techVisitsCountFromDate.closed.global }}
            </li>
            <li class="list-group-item">
              <b>Fiber:</b> {{ techVisitsCountFromDate.closed.fiber }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-2">
        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item title"
              title="Média de visitas técnicas por dia no mês atual"
            >
              <span>Média de visitas técnicas no mês atual</span>
            </li>
            <li class="list-group-item">
              <b>Total:</b> {{ Math.round(techVisitsAvg.total) }}/dia
            </li>
            <li class="list-group-item">
              <b>Global:</b> {{ Math.round(techVisitsAvg.global) }}/dia
            </li>
            <li class="list-group-item">
              <b>Fiber:</b> {{ Math.round(techVisitsAvg.fiber) }}/dia
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title">
              <span title="Percentual (VT dia anterior/ativos)"
                >Percentual ontem (VT/ativos)</span
              >
            </li>
            <li class="list-group-item">
              <b>Percentual Total:</b>
              {{
                (
                  +techVisitsCountFromDate.closed.total / clientsCount.total
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Global:</b>
              {{
                (
                  +techVisitsCountFromDate.closed.global / clientsCount.global
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Fiber:</b>
              {{
                (
                  +techVisitsCountFromDate.closed.fiber / clientsCount.fiber
                ).toFixed(2)
              }}%
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item title"
              title="nº visita técnica fechadas"
            >
              <span>Qtd VT fechadas (total)</span>
            </li>
            <li class="list-group-item">
              <b>Nº VT:</b> {{ techVisitsCount.closed.total }}
            </li>
            <li class="list-group-item">
              <b>Global:</b> {{ techVisitsCount.closed.global }}
            </li>
            <li class="list-group-item">
              <b>Fiber:</b> {{ techVisitsCount.closed.fiber }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-2">
        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title">
              <span title="Percentual (VT mês atual/ativos)"
                >Percentual VT Mês Atual</span
              >
            </li>
            <li class="list-group-item">
              <b>Percentual Total:</b>
              {{
                (
                  (+techVisitsCountFromRange.total +
                    +techVisitsCountFromRange.total) /
                  clientsCount.total
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Global:</b>
              {{
                (
                  (+techVisitsCountFromRange.global +
                    +techVisitsCountFromRange.global) /
                  clientsCount.global
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Fiber:</b>
              {{
                (
                  (+techVisitsCountFromRange.fiber +
                    +techVisitsCountFromRange.fiber) /
                  clientsCount.fiber
                ).toFixed(2)
              }}%
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title">
              <span title="Percentual VT Ultimos 3 meses"
                >Percentual VT Ultimos 3 meses</span
              >
              <span title="Este calculo precisa ser revisto"
                ><i class="fa fa-exclamation-triangle"></i
              ></span>
            </li>
            <li class="list-group-item">
              <b>Percentual Total:</b>
              {{
                (
                  +techVisitsCountLastThreeMonths.total / clientsCount.total
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Global:</b>
              {{
                (
                  +techVisitsCountLastThreeMonths.global / clientsCount.global
                ).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Fiber:</b>
              {{
                (
                  +techVisitsCountLastThreeMonths.fiber / clientsCount.fiber
                ).toFixed(2)
              }}%
            </li>
          </ul>
        </div>

        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title">
              <span title="Percentual (VT total/ativos)"
                >Percentual total (VT/ativos)</span
              >
            </li>
            <li class="list-group-item">
              <b>Percentual Total:</b>
              {{
                (+techVisitsCount.closed.total / clientsCount.total).toFixed(2)
              }}%
            </li>
            <li class="list-group-item">
              <b>Global:</b>
              {{
                (+techVisitsCount.closed.global / clientsCount.global).toFixed(
                  2
                )
              }}%
            </li>
            <li class="list-group-item">
              <b>Fiber:</b>
              {{
                (+techVisitsCount.closed.fiber / clientsCount.fiber).toFixed(2)
              }}%
            </li>
          </ul>
        </div>
      </div>

      <div class="row mt-2">
        <div class="card col-md-3 ml-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item title">
              <span title="Média VT por dia dos ultimos 6 meses"
                >Média VT Ultimos 6 meses</span
              >
            </li>
            <li class="list-group-item">
              <b>Rogue:</b>
              {{
                (
                  (+techVisitsCountFromRange.total +
                    +techVisitsCountFromRange.total) /
                  clientsCount.total
                ).toFixed(2)
              }}/dia
            </li>
            <li class="list-group-item">
              <b>Mage:</b>
              {{
                (
                  (+techVisitsCountFromRange.global +
                    +techVisitsCountFromRange.global) /
                  clientsCount.global
                ).toFixed(2)
              }}/dia
            </li>
            <li class="list-group-item">
              <b>Priest:</b>
              {{
                (
                  (+techVisitsCountFromRange.fiber +
                    +techVisitsCountFromRange.fiber) /
                  clientsCount.fiber
                ).toFixed(2)
              }}/dia
            </li>
            <li class="list-group-item">
              <b>Shamam:</b>
              {{
                (
                  (+techVisitsCountFromRange.fiber +
                    +techVisitsCountFromRange.fiber) /
                  clientsCount.fiber
                ).toFixed(2)
              }}/dia
            </li>
            <li class="list-group-item">
              <b>Monk:</b>
              {{
                (
                  (+techVisitsCountFromRange.fiber +
                    +techVisitsCountFromRange.fiber) /
                  clientsCount.fiber
                ).toFixed(2)
              }}/dia
            </li>
            <li class="list-group-item">
              <b>Warrior kekw:</b>
              {{
                (
                  (+techVisitsCountFromRange.fiber +
                    +techVisitsCountFromRange.fiber) /
                  clientsCount.fiber
                ).toFixed(2)
              }}/dia
            </li>
          </ul>
        </div>
      </div>

      <hr />
      <h3>Busca por Data</h3>

      <form>
        <div class="row">
          <form action="">
            <input
              class="col-md-3 ml-2"
              type="date"
              v-model="start"
              :max="new Date().toISOString().split('T')[0]"
            />
            <input
              class="col-md-3 ml-2"
              type="date"
              v-model="end"
              :max="new Date().toISOString().split('T')[0]"
            />
            <button
              class="btn_search col-md-2 ml-2"
              type="button"
              @click="onSearchByRange"
            >
              Buscar
            </button>
          </form>
        </div>

        <br />
        <div class="row" v-show="this.hasDateSearched">
          <div class="card col-md-3 ml-2">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item title"
                title="nº visita tecnica pesquisada"
              >
                <span>Qtd VT</span>
              </li>
              <li class="list-group-item">
                <b>Total:</b> {{ dateSearch.techVisitsCount.total }}
              </li>
              <li class="list-group-item">
                <b>Global:</b> {{ dateSearch.techVisitsCount.global }}
              </li>
              <li class="list-group-item">
                <b>Fiber:</b> {{ dateSearch.techVisitsCount.fiber }}
              </li>
            </ul>
          </div>

          <div class="card col-md-3 ml-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item title" title="média VT pesquisada">
                <span>Média VT</span>
              </li>
              <li class="list-group-item">
                <b>Total:</b>
                {{ Math.round(dateSearch.techVisitsAvg.total) }}/dia
              </li>
              <li class="list-group-item">
                <b>Global:</b>
                {{ Math.round(dateSearch.techVisitsAvg.global) }}/dia
              </li>
              <li class="list-group-item">
                <b>Fiber:</b>
                {{ Math.round(dateSearch.techVisitsAvg.fiber) }}/dia
              </li>
            </ul>
          </div>

          <div class="card col-md-3 ml-2">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item title"
                title="Percentual (VT pesquisado/ativos)"
              >
                <span>Percentual VT / Clientes</span>
              </li>
              <li class="list-group-item">
                <b>Total:</b>
                {{
                  (
                    +dateSearch.techVisitsCount.total / clientsCount.total
                  ).toFixed(2)
                }}%
              </li>
              <li class="list-group-item">
                <b>Global:</b>
                {{
                  (
                    +dateSearch.techVisitsCount.global / clientsCount.global
                  ).toFixed(2)
                }}%
              </li>
              <li class="list-group-item">
                <b>Fiber:</b>
                {{
                  (
                    +dateSearch.techVisitsCount.fiber / clientsCount.fiber
                  ).toFixed(2)
                }}%
              </li>
            </ul>
          </div>
        </div>
      </form>

      <!-- <hr />
      <h3>Busca por Técnico</h3>
      <form action="">
        <div class="row">
          <select class="col-md-3 ml-2" name="" id="">
            <option selected disabled value="">Selecione um técnico...</option>
            <option value="">Jorge</option>
            <option value="">Augusto</option>
            <option value="">Vitão</option>
          </select>

          <input class="col-md-3" type="date" />
          <input class="col-md-3 ml-2" type="date" />
          <button class="btn_search col-md-2 ml-2" type="">Buscar</button>
        </div>

        <br />
        <div class="row">
          <div class="card col-md-3 ml-2">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item title"
                title="quantidade VT tecnico pesquisado"
              >
                <span>Qtd VT do Técnico</span>
              </li>
              <li class="list-group-item"><b>Média:</b> 8.573</li>
            </ul>
          </div>
          <div class="card col-md-3 ml-2">
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item title"
                title="média VT tecnico pesquisado"
              >
                <span>Média VT do Técnico</span>
              </li>
              <li class="list-group-item"><b>Média:</b> 8.573</li>
            </ul>
          </div>
        </div>
      </form> -->
    </base-header>
  </div>
</template>
<script>
import swal from "sweetalert2";

export default {
  components: {},
  computed: {},
  data() {
    return {
      start: null,
      end: null,
      hasDateSearched: false,
      clientsCount: {
        fiber: "0",
        global: "0",
        total: "0",
      },
      techVisitsCount: {
        open: {
          fiber: "0",
          global: "0",
          total: "0",
        },
        closed: {
          fiber: "0",
          global: "0",
          total: "0",
        },
      },
      techVisitsCountFromDate: {
        open: {
          fiber: "0",
          global: "0",
          total: "0",
        },
        closed: {
          fiber: "0",
          global: "0",
          total: "0",
        },
      },
      techVisitsCountFromRange: {
        fiber: "0",
        global: "0",
        total: "0",
      },
      techVisitsCountLastThreeMonths: {
        fiber: "0",
        global: "0",
        total: "0",
      },
      techVisitsAvg: {
        fiber: "0",
        global: "0",
        total: "0",
      },
      dateSearch: {
        techVisitsCount: {
          open: {
            fiber: "0",
            global: "0",
            total: "0",
          },
          closed: {
            fiber: "0",
            global: "0",
            total: "0",
          },
        },
        techVisitsAvg: {
          fiber: "0",
          global: "0",
          total: "0",
        },
      },
    };
  },
  methods: {
    async getClientsCount() {
      return this.$http.get("clients/count");
    },
    async getTechVisitCount() {
      return this.$http.get("techvisits/count");
    },
    async getTechVisitCountFromDate(date) {
      return this.$http.get("techvisits/countfromdate", {
        params: {
          date,
        },
      });
    },
    async getTechVisitAvg(start, end) {
      return this.$http.get("techvisits/avg", {
        params: {
          start,
          end,
        },
      });
    },
    async getTechVisitCountFromRange(start, end) {
      return this.$http.get("techvisits/countfromrange", {
        params: {
          start,
          end,
        },
      });
    },
    async onSearchByRange() {
      if (!this.start || !this.end) {
        swal.fire("Ops!", "Selecione as datas antes!", "error");
        return;
      }

      const start = new Date(this.start);
      const end = new Date(this.end);

      if (start > end) {
        swal.fire(
          "Ops!",
          "A data inicio não pode ser maior que a final!",
          "error"
        );
        return;
      }

      try {
        const [techVisitCount, techVisitsAvg] = await Promise.all([
          this.getTechVisitCountFromRange(this.start, this.end),
          this.getTechVisitAvg(this.start, this.end),
        ]);

        this.dateSearch.techVisitsCount = techVisitCount.data;
        this.dateSearch.techVisitsAvg = techVisitsAvg.data;
        this.hasDateSearched = true;
      } catch (err) {
        swal.fire("Ops!", "Não foi possível fazer a busca!", "error");
      }
    },
  },
  async mounted() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const start = new Date(y, m, 1);
    const end = new Date();

    const startThreeMonths = new Date();
    startThreeMonths.setDate(1);
    startThreeMonths.setMonth(startThreeMonths.getMonth() - 1);

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    try {
      const [
        techVisitsCountFromRange,
        techVisitsAvg,
        clientsCount,
        techVisitsCount,
        techVisitsCountFromDate,
        techVisitsCountLastThreeMonths,
      ] = await Promise.all([
        this.getTechVisitCountFromRange(
          start.toISOString().split("T")[0],
          end.toISOString().split("T")[0]
        ),
        this.getTechVisitAvg(
          start.toISOString().split("T")[0],
          end.toISOString().split("T")[0]
        ),
        this.getClientsCount(),
        this.getTechVisitCount(),
        this.getTechVisitCountFromDate(yesterday.toISOString().split("T")[0]),
        this.getTechVisitCountFromRange(
          startThreeMonths.toISOString().split("T")[0],
          end.toISOString().split("T")[0]
        ),
      ]);

      this.techVisitsCountFromRange = techVisitsCountFromRange.data;
      this.techVisitsAvg = techVisitsAvg.data;
      this.clientsCount = clientsCount.data;
      this.techVisitsCount = techVisitsCount.data;
      this.techVisitsCountFromDate = techVisitsCountFromDate.data;
      this.techVisitsCountLastThreeMonths = techVisitsCountLastThreeMonths.data;
    } catch (err) {
      swal.fire("Ops!", "Não foi possível obter os dados!", "error");
    }
  },
};
</script>

<style scoped>
.list-group-item {
  padding: 5px;
}
.list-group-item.title {
  text-align: center;
  font-weight: bold;
}
.list-group-item.title span {
  color: #a1c883;
}
.card,
input {
  border: 1px solid #000;
}
.card,
ul,
li {
  background-color: #f8f5f5;
}
hr,
h3 {
  color: #fff;
}

button.btn_search {
  border: 1px solid #000;
  border-radius: 3px;
  background-color: #f8f5f5;
  padding: 3.5px;
  font-size: 13px;
}
</style>
