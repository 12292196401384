<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <h1>Olá,</h1>
      <h2>selecione uma dashboard</h2>

      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <router-link to="/dashboard">
          <stats-card
            style="border: 2px solid #a9cd84;"
            type="gradient-green"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          <p>URA</p>
          </stats-card>
          </router-link>
        </div>

        <div class="col-xl-3 col-lg-6">
          <router-link to="/at1">
          <stats-card
            style="border: 2px solid #a9cd84;"
            type="gradient-green"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          <p>COP - AT1</p>
          </stats-card>
          </router-link>
        </div>

        <!-- <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #a9cd84;"
            type="gradient-green"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          <p>COP - AT2</p>
          </stats-card>
        </div> -->

        <!-- <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #a9cd84;"
            type="gradient-green"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          <p>COP - AT3</p>
          </stats-card>
        </div> -->
      </div>

      <!-- <br><br>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #a9cd84;"
            type="gradient-green"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          <p>COP - AT4</p>
          </stats-card>
        </div>
      </div> -->
      
    </base-header>
  </div>
</template>
<script>
// Charts

export default {
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  async created() {},
  beforeDestroy() {},
};
</script>

<style scoped>
p {
  font-size: 25px;
  font-weight: bold;
}
h1 {
  color: #a1c883;
}
h2 {
  color: #fff;
}
.card {
  min-height: 150px;
}
.card p {
  margin: auto;
  color: #000;
  font-size: 25px;
  font-weight: bold;
}
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #f5f3f3;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}
.modal-close {
  color: #fc3e3e;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}
/* Demo Styles */

a {
  color: inherit;
}
.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}
small {
  color: #aaa;
}
.btn {
  background-color: #fff;
  padding: 1em 1.5em;
  border-radius: 3px;
  text-decoration: none;
}
.btn i {
  padding-right: 0.3em;
}

.calls {
  display: flex;
  color: #a1c883;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.calls p {
  flex: 1;
  color: #086b75;
  font-size: 18px;
}
.calls p span {
  font-size: 20px;
  font-weight: bold;
  color: #91b46d;
}
.calls p:nth-child(3) span {
  color: red !important;
}
</style>
