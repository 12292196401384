<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <h2 style="color: #abce87">Chamadas</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #a9cd84"
            title="Chamadas em Curso"
            type="gradient-green"
            v-bind:sub-title="count.activeCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #0c66ad"
            title="Chamadas em Espera"
            type="gradient-blue"
            v-bind:sub-title="count.onHoldCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            style="border: 2px solid #f5220d"
            title="Chamadas em Alerta"
            type="gradient-red"
            v-bind:sub-title="count.alertCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>

      <br />
      <div class="calls">
        <p>
          Atendidas: <span>{{ count.answeredCalls }}</span
          >({{ answeredCallsPercentage }}%)
        </p>
        <p>TMA: <span>3</span> minutos</p>
        <p>
          Perdidas: <span>{{ count.missingCalls }}</span
          >({{ missingCallsPercentage }}%)
        </p>
        <p>TME: <span>40</span> segundos</p>
      </div>

      <br />

      <h2 style="color: #abce87">Atendentes</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <a href="#open-modal">
          <stats-card 
            style="border: 2px solid #a9cd84"
            title="Atendentes Disponíveis"
            type="gradient-green"
            v-bind:sub-title="agents.onlineAgents.length"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
          </a>
        </div>
        <div class="col-xl-3 col-lg-6">
          <a href="#open-modal-paused">
          <stats-card
            style="border: 2px solid #0c66ad"
            title="Atendentes em Pausa"
            type="gradient-blue"
            v-bind:sub-title="agents.pausedAgents.length"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
          </a>
        </div>
        <div class="col-xl-3 col-lg-6">
          <a href="#open-modal-busy">
          <stats-card
            style="border: 2px solid #f5220d"
            title="Atendentes Ocupado"
            type="gradient-red"
            v-bind:sub-title="agents.busyAgents.length"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
          </a>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <!--Tables-->
      <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <page-visits-table :data="calls"></page-visits-table>
        </div>
      </div>
      <!--End tables-->
    </div>
    
    <!--Modal disponveis -->
    <div id="open-modal" class="modal-window">
      <div>
        <a href="#" title="Close" class="modal-close">Fechar</a>
        <h1>Atendentes Disponíveis</h1>
        <ul v-for="agent of agents.onlineAgents" :key="agent.id">
          <li>{{ agent.name }}</li>
        </ul>
      </div>
    </div>

    <!--Modal Pausa -->
    <div id="open-modal-paused" class="modal-window">
      <div>
        <a href="#" title="Close" class="modal-close">Fechar</a>
        <h1>Atendentes em Pausas</h1>
        <ul v-for="agent of agents.pausedAgents" :key="agent.id">
          <li>{{ agent.name }}</li>
        </ul>
      </div>
    </div>

    <!--Modal Ocupado -->
    <div id="open-modal-busy" class="modal-window">
      <div>
        <a href="#" title="Close" class="modal-close">Fechar</a>
        <h1>Atendentes Ocupados</h1>
        <ul v-for="agent of agents.busyAgents" :key="agent.id">
          <li>{{ agent.name }}</li>
        </ul>
      </div>
    </div>
    
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";

// Tables
import PageVisitsTable from "./Dashboard/PageVisitsTable";

export default {
  components: {
    PageVisitsTable,
  },
  computed: {
    answeredCallsPercentage() {
      const total = this.count.answeredCalls + this.count.missingCalls;
      if (total === 0) return 0;
      return Math.round((this.count.answeredCalls / total) * 100);
    },
    missingCallsPercentage() {
      const total = this.count.answeredCalls + this.count.missingCalls;
      if (total === 0) return 0;
      return Math.round((this.count.missingCalls / total) * 100);
    },
  },
  data() {
    return {
      calls: [],
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
      count: {
        activeCalls: 0,
        onHoldCalls: 0,
        alertCalls: 0,
        answeredCalls: 0,
        missingCalls: 0,
      },
      refreshTimer: null,
      agents: {
        onlineAgents: [],
        busyAgents: [],
        pausedAgents: [],
        rankingAgents: [],
      },
    };
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },

    async getCalls() {
      try {
        const response = await this.$http.get("calls/all");
        this.count.activeCalls = response.data.activeCalls.length;
        this.count.onHoldCalls = response.data.onHoldCalls.length;
        this.count.alertCalls = response.data.alertCalls.length;
        this.count.answeredCalls = response.data.answeredCalls.length;
        this.count.missingCalls = response.data.missingCalls.length;

        this.calls = [];

        this.calls = this.calls.concat(
          response.data.alertCalls.map((call) => ({
            agente: call.agent_name || "Aguardando",
            fila: call.fila || "-",
            origem: call.callerid || "-",
            tempo: "-",
            tempoEspera: this.secondsToTime(
              this.calcElapsedTime(new Date(call.datetime_entry_queue))
            ),
            status: this.translateStatus(call.status),
          }))
        );

        this.calls = this.calls.concat(
          response.data.activeCalls.map((call) => ({
            agente: call.agent_name,
            fila: call.fila,
            origem: call.callerid,
            tempo: this.secondsToTime(
              this.calcElapsedTime(new Date(call.datetime_init))
            ),
            tempoEspera: this.secondsToTime(call.duration_wait),
            status: this.translateStatus(call.status),
          }))
        );
      } catch (err) {
        console.log(err.response);
      }
    },

    secondsToTime(sec) {
      let hours = Math.floor(sec / 3600);
      let minutes = Math.floor((sec - hours * 3600) / 60);
      let seconds = sec - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + "h " + minutes + "m " + seconds + "s";
    },

    calcElapsedTime(startTime) {
      startTime.setHours(startTime.getHours() - 1);

      const endTime = new Date();
      let timeDiff = endTime - startTime; //in ms

      // strip the ms
      timeDiff /= 1000;

      // get seconds
      return Math.round(timeDiff);
    },

    translateStatus(status) {
      switch (status) {
        case "en-cola":
          return "Alerta";
        case "activa":
          return "Ativa";
        default:
          return "-";
      }
    },

    async getAgents() {
      try {
        const response = await this.$http.get("agents/all");
        this.agents.onlineAgents = response.data.onlineAgents;
        this.agents.pausedAgents = response.data.pausedAgents;
        this.agents.busyAgents = response.data.busyAgents;
        this.agents.rankingAgents = response.data.rankingAgents;
      } catch (err) {
        console.log(err.response);
      }
    },
  },
  mounted() {
    this.initBigChart(0);
  },
  async created() {
    await this.getCalls();
    await this.getAgents();
    this.refreshTimer = setInterval(() => this.getCalls(), 5000);
    this.refreshTimer = setInterval(() => this.getAgents(), 5000);
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  },
};
</script>

<style scoped>
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #f5f3f3;
}
.modal-window header {
  font-weight: bold;
}
.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}
.modal-close {
  color: #fc3e3e;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}
/* Demo Styles */

a {
  color: inherit;
}
.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}
small {
  color: #aaa;
}
.btn {
  background-color: #fff;
  padding: 1em 1.5em;
  border-radius: 3px;
  text-decoration: none;
}
.btn i {
  padding-right: 0.3em;
}

.calls {
  display: flex;
  color: #a1c883;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.calls p {
  flex: 1;
  color: #086b75;
  font-size: 18px;
}
.calls p span {
  font-size: 20px;
  font-weight: bold;
  color: #91b46d;
}
.calls p:nth-child(3) span {
  color: red !important;
}
</style>
