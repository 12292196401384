<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Chamadas Ativas</h3>
        </div>
        <div class="col text-right">
          <a href="#!" class="btn btn-sm btn-primary">Ver Todos</a>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="data">
        <template slot="columns">
          <th>Agente</th>
          <th>Fila</th>
          <th>Origem</th>
          <th>Tempo Decorrido</th>
          <th>Tempo Espera</th>
          <th>Status</th>
          <th>Ações</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            {{ row.agente }}
          </th>
          <td>
            {{ row.fila }}
          </td>
          <td>
            {{ row.origem }}
          </td>
          <td>
            {{ row.tempo }}
          </td>
          <td>
            {{ row.tempoEspera }}
          </td>
          <td>
            <span
              :class="{
                'badge-primary': row.status != 'Alerta',
                'badge-danger': row.status == 'Alerta',
                'badge-pill': true,
              }"
              >{{ row.status }}</span
            >
          </td>
          <td>
            <i class="ni ni-headphones"></i>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "page-visits-table",
  props: {
    data: Array,
  },
};
</script>
<style>
</style>
