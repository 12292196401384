<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <h2 style="color: #abce87">Chamadas</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Chamadas em Curso"
            type="gradient-green"
            v-bind:sub-title="count.activeCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Chamadas em Espera"
            type="gradient-blue"
            v-bind:sub-title="count.onHoldCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Chamadas em Alerta"
            type="gradient-red"
            v-bind:sub-title="count.alertCalls"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>

      <br />

      <h2 style="color: #abce87">Atendentes</h2>
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Atendentes Disponíveis"
            type="gradient-green"
            v-bind:sub-title="agentsCount.onlineAgents"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Atendentes em Pausa"
            type="gradient-blue"
            v-bind:sub-title="agentsCount.pausedAgents"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Atendentes Ocupado"
            type="gradient-red"
            v-bind:sub-title="agentsCount.busyAgents"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <projects-table title="Ranking de Atendimento" :data="agents"></projects-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectsTable from "./Tables/ProjectsTable";
export default {
  name: "tables",
  components: {
    ProjectsTable,
  },

  data() {
    return {
      agents: [],
      count: {
        activeCalls: 0,
        onHoldCalls: 0,
        alertCalls: 0,
        answeredCalls: 0,
        missingCalls: 0
      },
      refreshTimer: null,
      agentsCount: {
        onlineAgents: 0,
        busyAgents: 0,
        pausedAgents: 0,
        rankingAgents: 0,
      },
    };
  },
  methods: {
    async calls() {
      try {
        const response = await this.$http.get("calls/all");
        this.count.activeCalls = response.data.activeCalls.length
        this.count.onHoldCalls = response.data.onHoldCalls.length
        this.count.alertCalls = response.data.alertCalls.length
        this.count.answeredCalls = response.data.answeredCalls.length
        this.count.missingCalls = response.data.missingCalls.length
      } catch (err) {
        console.log(err.response)
      }
    },

     async getAgents() {
      try {
        const response = await this.$http.get("agents/all");
        this.agentsCount.onlineAgents = response.data.onlineAgents.length;
        this.agentsCount.pausedAgents = response.data.pausedAgents.length;
        this.agentsCount.busyAgents = response.data.busyAgents.length;
        this.agentsCount.rankingAgents = response.data.rankingAgents;

        this.agents = [];

        this.agents = this.agents.concat(
          response.data.rankingAgents.map((agent, index) => ({
            ranking: index + 1,
            name: agent.name,
            quantity: agent.call_count
          }))
        );
      } catch (err) {
        console.log(err.response);
      }
    },
  },
  mounted() {
    
  },
   async created() {
    await this.calls();
    await this.getAgents();
    this.refreshTimer = setInterval(() => this.calls(), 5000);
    this.refreshTimer = setInterval(() => this.getAgents(), 5000);
  },
  beforeDestroy() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
};
</script>
<style></style>
