<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 600px;
        background-image: url(img/theme/profile-cover.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <h1 class="display-2 text-white">Cadastrar Usuário</h1>
            <p class="text-white mt-0 mb-5">
              Cadastre usuários para ter acesso a plataforma, podendo assim visualizar todas as informações que contem na dashboard
            </p>
            <button><a>Visualizar Usuários</a></button>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Cadastrar Usuário</h3>
                </div>
                <!-- <div class="col-4 text-right">
                  <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                </div> !-->
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">Meus dados</h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Nome"
                        placeholder="Digite seu nome..."
                        input-classes="form-control-alternative"
                        v-model="model.name"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Sobrenome"
                        placeholder="Digite seu sobrenome..."
                        input-classes="form-control-alternative"
                        v-model="model.lastName"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="E-mail"
                        placeholder="Digite seu e-mail..."
                        input-classes="form-control-alternative"
                        v-model="model.email"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Senha"
                        placeholder="Digite sua senha..."
                        input-classes="form-control-alternative"
                        v-model="model.password"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Informações para Contato
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        alternative=""
                        label="Endereço"
                        placeholder="Digite seu endereço..."
                        input-classes="form-control-alternative"
                        v-model="model.address"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Estado"
                        placeholder="Digite seu estado..."
                        input-classes="form-control-alternative"
                        v-model="model.state"
                      />
                    </div>
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Cidade"
                        placeholder="Digite sua cidade..."
                        input-classes="form-control-alternative"
                        v-model="model.city"
                      />
                    </div>
                    
                    <div class="col-lg-4">
                      <base-input
                        alternative=""
                        label="Celular"
                        placeholder="Digite seu celular para contato..."
                        input-classes="form-control-alternative"
                        v-model="model.tel"
                      />
                    </div>
                  </div>
                  <button>Cadastrar</button>
                </div>

                <hr class="my-4" />
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "user-profile",
  data() {
    return {
      model: {
        name: "",
        lastName: "",
        email: "",
        password: "",
        address: "",
        state: "",
        city: "",
        tel: "",
      },
    };
  },
};
</script>
<style scoped>
  .card-body {
    background: #fbfbfb!important;
  }

  button {
    background-color: #005663;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
  }
</style>
